import { motion } from "framer-motion";
import DG from "../assets/main.jpg";
import { Helmet } from "react-helmet-async";

const Landing = ({ setSelectedPage }) => {
  return (
    <section
      id="home"
      className="flex mt-32 md:mt-40 gap-8 items-start flex-col md:flex-row"
    >
      <Helmet>
        <title>DSpace - Home</title>
        <meta
          name="description"
          content="The DSpace digital marketing agency is here to grow your business Faster. Harness the power of digital marketing to grow your business"
        />
        <meta
          name="keywords"
          content="Digital marketing agency, Digital marketing agency in India, Digital marketing agency in Udaipur Digital marketing agency in Dubai, Digital marketing agency in the USA, Digital marketing agency in
          Europe, Best digital marketing agency, Best digital marketing agency in India, Best digital marketing agency in Udaipur, Digital marketing services, Digital marketing company, SEO, SMM, Content creation, Video creation, Graphic design, Website creation"
        />

        <link rel="canonical" href="/" />
      </Helmet>
      <div className=" md:basis-3/5 flex justify-center md:order-2 text-myBlack">
        <div className="md:ml-20">
          <img loading="lazy" src={DG} alt="" />
        </div>
      </div>

      {/* TEXT */}
      <div className=" md:basis-2/5 md:ml-10 text-myBlack">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className=" text-[30px] md:text-[50px] sm:text-[35px] text-center md:text-left font-semibold leading-[2.5rem] md:leading-[5rem]">
            Harness the power of digital marketing to grow your business
          </p>
          <p className="text-myBlack font-extrabold text-[30px] md:text-[50px] md:text-left text-center ">
            FASTER
          </p>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <a className="rounded-r-sm py-0.5 pr-0.5" href="/contactus">
            <div className="bg-none border-2 border-myBlack py-2 px-5 text-xl rounded-full hover:bg-myBlack hover:text-white transition duration-350 font-semibold">
              Let's talk
            </div>
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
