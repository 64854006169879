import { motion } from "framer-motion";
import Insta from "../assets/ig.png";
import Fb from "../assets/fb.png";
import Twit from "../assets/tw.png";
import Linked from "../assets/linkedin.png";

const BSMedia = () => {
  return (
    <div className="bg-blue-700">
      <motion.div
        className="flex justify-center md:justify-start flex-col items-center gap-2"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className=" md:hidden bg-white h-[1px] w-full"></div>
        <div
          className=" md:hidden
           p-1 rounded-md flex text-3xl gap-5 items-center cursor-pointer text-[#1053f3]"
        >
          <a href="https://www.instagram.com/dspace.infusion/">
            <img src={Insta} className="h-10" alt="" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100092644065926">
            <img src={Fb} className="h-10" alt="" />
          </a>
          <a href="https://twitter.com/DSpaceinfusion">
            <img src={Twit} className="h-10" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/dspace-infusion/?viewAsMember=true">
            <img src={Linked} className="h-10" alt="" />
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default BSMedia;
