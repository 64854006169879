import { motion } from "framer-motion";

const Footer = () => {
  return (
    <section className="ftr bg-cyan-100 text-myBlack mt-10 py-5  font-mont">
      <div className="w-[90%] mx-auto flex flex-wrap sm:flex-nowrap justify-between gap-6">
        <div className="text-md sm:text-lg  sm:w-[20%]">
          <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <p className="font-semibold border-2 border-black p-2 rounded-md">
              Congratulations! You've already taken your first step and are
              travelling from start to end with us. It helps with the fitness of
              your business.
            </p>
          </motion.div>
        </div>
        <div>
          <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <a
              href="/services"
              className="text-lg sm:text-xl font-bold underline"
            >
              Services
            </a>
            <div className="mt-2">
              <p>Social Media Marketing</p>
              <p>Branding</p>
              <p>SEO</p>
              <p>Content Marketing</p>
              <p>Video Marketing</p>
              <p>Analytics & Reporting</p>
            </div>
          </motion.div>
        </div>
        <div>
          <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <a
              href="/ourteam"
              className="text-lg sm:text-xl font-bold underline"
            >
              Our Team
            </a>

            <div className="mt-2">
              <p>Tushar</p>
              <p>Twinkle</p>
              <p>Himanshu</p>
            </div>
          </motion.div>
        </div>
        <div>
          <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <a
              href="/contactus"
              className="text-lg sm:text-xl font-bold underline"
            >
              Contact Us
            </a>

            <div className="mt-2">
              <p>dspaceinfulsion@gmail.com</p>
              <p>+91 7665588851</p>
              <p>www.dspace.co.in</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
