import { motion } from "framer-motion";
import Looking from "../assets/looking.png";
import Rocket from "../assets/rocket.png";

const Hometext = () => {
  return (
    <div className="bg-[#FFA559] relative mt-20">
      <motion.div
        className="mt-10 text-center text-[35px] w-[90%] mx-auto flex flex-col sm:flex-row"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ delay: 0.4, duration: 0.2 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <img src={Looking} className="h-48 sm:-ml-10 object-contain " alt="" />
        <p className=" py-10 sm:py-36 text-myBlack font-semibold ">
          "Looking to
          <span className="font-extrabold text-[40px]"> become</span> the
          <span className="font-extrabold text-[40px]"> next</span> big name
          <span className="font-extrabold text-[40px]"> everyone</span> is
          talking about"
        </p>
        <img
          src={Rocket}
          className="h-48 sm:pt-5 sm:absolute sm:bottom-0 sm:right-20 object-contain"
          alt=""
        />
      </motion.div>
    </div>
  );
};

export default Hometext;
