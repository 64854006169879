import { motion } from "framer-motion";
import Branding from "../assets/branding.png";
import ContentM from "../assets/cmarketing.png";
import SocialM from "../assets/smarketing.jpg";
import VidAnimation from "../assets/vmarketing.png";
import Seo from "../assets/seo.png";
import Analytics from "../assets/analytics.jpg";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <section id="services" className="pt-24 text-myBlack">
      <Helmet>
        <title>DSpace - Services</title>
        <link rel="canonical" href="/services" />
      </Helmet>
      <div className="text-center">
        <motion.div
          className=""
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="highlight-2 font-bold text-[35px] md:text-[50px] max-w-fit mx-auto mb-5 text-myBlack">
            Our Services
          </p>

          <p className="mt-8 font-bold text-[18px]">
            "Transform your digital landscape with our innovative approaches"
          </p>
        </motion.div>
      </div>

      <div className="flex flex-wrap mt-16 gap-8 justify-between">
        <motion.div
          className=" bg-blue-100 p-2 rounded-md flex flex-col sm:flex-row"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={Branding}
              className="object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              Branding
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Our branding services encompass logo design, brand messaging,
              brand guidelines, and visual identity development. We ensure
              consistency across all digital channels to create a memorable and
              impactful brand presence.
            </p>
          </div>
        </motion.div>

        <motion.div
          className=" bg-cyan-100 p-2 rounded-md flex flex-col sm:flex-row-reverse"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={ContentM}
              className="object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              Content Marketing
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Create valuable, informative, and engaging content in various
              formats (blog posts, articles, videos, infographics) to attract
              and engage the target audience, drive brand authority, and support
              SEO efforts.
            </p>
          </div>
        </motion.div>

        <motion.div
          className=" bg-purple-100 p-2 rounded-md flex flex-col sm:flex-row"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={SocialM}
              className="object-cover sm:object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              Social Media Marketing
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Develop and execute social media strategies to build brand
              awareness, engage with the target audience, and drive traffic and
              conversions through platforms such as Facebook, Instagram,
              Twitter, LinkedIn, and more.
            </p>
          </div>
        </motion.div>

        <motion.div
          className=" bg-yellow-100 p-2 rounded-md flex flex-col sm:flex-row-reverse"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={Seo}
              className="object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              SEO
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Search Engine Optimization (SEO): Enhance a website's visibility
              in search engine results pages through organic strategies, keyword
              research, on-page optimization, link building, and content
              optimization.
            </p>
          </div>
        </motion.div>

        <motion.div
          className=" bg-[#c4ffcd] p-2 rounded-md flex flex-col sm:flex-row"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={VidAnimation}
              className="object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              Video Marketing
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Our video marketing services encompass the entire video production
              process, from conceptualization to scripting, filming, editing,
              and distribution.
            </p>
          </div>
        </motion.div>

        <motion.div
          className=" bg-red-200 p-2 rounded-md flex flex-col sm:flex-row-reverse"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex basis-2/5">
            <img
              loading="lazy"
              src={Analytics}
              className="object-cover sm:object-contain h-[260px] w-full"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex basis-3/5 flex-col justify-center">
            <p className="font-playfair font-semibold text-[26px] sm:text-4xl mt-3">
              Analytics & Reporting
            </p>
            <p className="mt-5 text-md sm:text-lg">
              Provide data analysis, tracking, and reporting services to measure
              the effectiveness of digital marketing efforts, identify areas for
              improvement, and make data-driven decisions.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
