import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import Logo from "../assets/dspace.png";
import Menu from "../assets/menu-icon.svg";
import Closee from "../assets/close-icon.svg";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 890px)");

  return (
    <div>
      {isDesktop ? (
        <div
          className={`max-h[20%] py-2 z-40 w-full fixed top-0 text-myblack sm:bg-[#f8f9ff] font-mont`}
        >
          <div className="flex items-center justify-between mx-auto w-[90%] relative">
            <div className="flex flex-col text-myBlack font-semibold items-center max-h-12">
              <img
                src={Logo}
                alt=""
                srcset=""
                className="h-24 absolute top-0 left-0 hidden sm:flex"
              />
              <p className="absolute -left-4 top-[4.9rem] text-[8px]">
                'YOUR WISH OUR RESOLUTION'
              </p>
            </div>
            <nav className="flex justify-between py-8  font-extrabold gap-16 text-xl text-myBlack relative">
              <NavLink to={"/"}>Home</NavLink>
              <NavLink to={"/aboutus"}>About Us</NavLink>
              <NavLink to={"/services"}>Services</NavLink>
              <NavLink to={"/ourteam"}>Our Team</NavLink>
              <NavLink to={"/contactus"}>Contact Us</NavLink>
            </nav>
          </div>
        </div>
      ) : (
        <div className=" bg-[#f8f9ff] z-40 fixed top-0 w-full">
          <div className="max-w-[90%] flex items-center justify-between mx-auto py-2">
            <div className="flex flex-col items-center">
              <img src={Logo} alt="" srcset="" className="h-16" />
              <p className=" text-myBlack font-semibold -mt-3 text-[10px]">
                .co.in
              </p>
            </div>

            <div>
              <button
                className="rounded-full bg-[#4967ff] p-2"
                onClick={() => setIsMenuToggled(!isMenuToggled)}
              >
                <img alt="menu-icon" src={Menu} />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* MOBILE MENU POPUP */}
      {!isDesktop && isMenuToggled && (
        <div className="fixed right-0 bottom-0 h-full bg-cyan-100  font-mont w-[300px] font-semibold z-50">
          {/* CLOSE ICON */}
          <div className="flex justify-end p-12 ">
            <button
              className="rounded-full bg-[#4967ff] p-2"
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              <img alt="close-icon" src={Closee} />
            </button>
          </div>

          <nav className="flex flex-col gap-10 ml-[33%] text-2xl text-myBlack ">
            <NavLink to={"/"} onClick={() => setIsMenuToggled(!isMenuToggled)}>
              Home
            </NavLink>
            <NavLink
              to={"/aboutus"}
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              About Us
            </NavLink>
            <NavLink
              to={"/services"}
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              Services
            </NavLink>
            <NavLink
              to={"/ourteam"}
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              Our Team
            </NavLink>
            <NavLink
              to={"/contactus"}
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              Contact Us
            </NavLink>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
