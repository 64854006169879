import { motion } from "framer-motion";
import Vision from "../assets/vision.png";
import Mission from "../assets/mission.png";
import Aimhigh from "../assets/aimhigh.png";
import Flexible from "../assets/flexible.png";
import Honest from "../assets/Honest.png";
import DigiM from "../assets/digim.jpg";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <section id="aboutus" className="pt-24 text-myBlack">
      <Helmet>
        <title>DSpace - AboutUs</title>
        <link rel="canonical" href="/aboutus" />
      </Helmet>
      <div className="text-center">
        <motion.div
          className=""
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="container w-fit mx-auto">
            <p className="highlight  font-bold text-[35px] md:text-[50px] mb-5 space-x-5">
              WE <span className="ml-3">ARE</span>
              <span className="text-red-600 ml-5"> दी</span>
              SPACE
            </p>
          </div>

          <p className="mt-5 font-bold">
            "Trust us we are bunch of Trained Pilots"
          </p>
        </motion.div>
      </div>

      <div className="flex flex-wrap mt-16 gap-8 justify-between ">
        {/* EXPERIENCE */}
        <motion.div
          className="bg-white p-2 rounded-md sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-4xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Experience
              </p>
            </div>
          </div>
          <p className="mt-0">
            Our team brings a wealth of experience in the digital marketing
            industry. With hands-on expertise, we have a deep understanding of
            the strategies, techniques, and trends that drive success in the
            digital realm.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="bg-white p-2 rounded-md sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-4xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Innovative
              </p>
            </div>
          </div>
          <p className="mt-0">
            We have a strong emphasis on embracing innovation. We actively seek
            out emerging trends, technologies, and strategies to provide our
            users with the latest and most effective digital marketing
            approaches.
          </p>
        </motion.div>

        {/* Data Driven */}
        <motion.div
          className="bg-white p-2 rounded-md sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-4xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Data Driven
              </p>
            </div>
          </div>
          <p className="mt-0">
            We believe that data has the power to drive effective digital
            marketing strategies. We emphasize the significance of analytics and
            tracking in determining the success of your campaigns. We help you
            make data-informed decisions.
          </p>
        </motion.div>

        {/* CC */}
        <motion.div
          className="bg-white p-2 rounded-md sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-4xl">04</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Customer Centric
              </p>
            </div>
          </div>
          <p className="mt-0">
            We prioritise our client's needs and goals. We are committed to
            understanding your issues, answering your questions, and providing
            customised solutions ensuring a smooth journey to the information
            and resources you require.
          </p>
        </motion.div>

        {/* img loading="lazy" */}

        <motion.div
          className="sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <img loading="lazy" src={DigiM} alt="" />
        </motion.div>

        {/* Growth */}
        <motion.div
          className="bg-white p-2 rounded-md sm:w-[40%] md:w-[30%]"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-4xl">05</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Growth Oriented
              </p>
            </div>
          </div>
          <p className="mt-0">
            Our top focus is your development and success. We are here to assist
            you in navigating the digital landscape, overcoming obstacles, and
            capitalising on opportunities. Our goal is to be your trusted
            partner on your digital marketing journey.
          </p>
        </motion.div>
      </div>

      <div className="mt-10">
        <motion.div
          className="flex flex-col gap-5"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="mission flex flex-col-reverse sm:flex-row justify-between">
            <div className="flex flex-col sm:basis-[60%] gap-5">
              <p className="font-bold text-2xl">Our Mission</p>
              <p className="text-[17px] font-medium">
                Our mission is to provide the best customer results possible and
                deliver the WOW factor through our services. Our mission is to
                be experts in Digital Marketing. Our team works to bring new
                life to your business which provides great height to your
                business.
              </p>
            </div>

            <img
              loading="lazy"
              src={Mission}
              className="h-40 flex sm:basis-[40%] object-contain"
              alt=""
              srcset=""
            />
          </div>
          <div className="vision flex flex-col-reverse sm:flex-row-reverse justify-between">
            <div className="flex flex-col sm:basis-[60%] gap-5">
              <p className="font-bold text-2xl">Our Vision</p>
              <p className="text-[17px] font-medium">
                Our vision is to pursue fast & sustainable growth for businesses
                worldwide. We want to be chosen comprehensive Digital Marketing
                partners in Udaipur. Deliver measurable results to clients,
                employees and vendors.
              </p>
            </div>

            <img
              loading="lazy"
              src={Vision}
              className="h-40 flex sm:basis-[40%] object-contain"
              alt=""
              srcset=""
            />
          </div>
        </motion.div>
      </div>

      <div className="mt-10">
        <motion.div
          className="flex flex-wrap justify-center flex-col sm:flex-row "
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="sm:w-[30%] bg-[#C85C8E] p-2 rounded-md flex flex-col gap-7">
            <h2 className="text-2xl font-bold text-center">Aim High</h2>
            <img
              loading="lazy"
              src={Aimhigh}
              alt=""
              className="h-32 object-contain"
              srcset=""
            />
            <p className="text-[18px]">
              We believe in trying to reach for
              <span className="font-bold"> BIG THINGS</span> in Life
            </p>
          </div>
          <div className="sm:w-[30%] bg-[#F7F1E5] p-2 rounded-md flex flex-col gap-7">
            <h2 className="text-2xl font-bold text-center">Be Flexible</h2>
            <img
              loading="lazy"
              src={Flexible}
              alt=""
              className="h-32 object-contain"
              srcset=""
            />
            <p className="text-[18px]">
              We are flexible to different needs and still work with team
              structure
            </p>
          </div>
          <div className="sm:w-[30%] bg-[#B5D5C5] p-2 rounded-md flex flex-col gap-7">
            <h2 className="text-2xl font-bold text-center">Play Honestly</h2>
            <img
              loading="lazy"
              src={Honest}
              alt=""
              className="h-32 object-contain"
              srcset=""
            />
            <p className="text-[18px]">
              It's important to fuel business with cash but more important is to
              earn them honestly
            </p>
          </div>
        </motion.div>
      </div>

      <div className="mt-10 p-2 rounded-md font-bold relative">
        <motion.div
          className="text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="z-50 border-2 border-black py-2 rounded-md">
            <a href="/contactus" className="text-[35px] highlight ">
              Connect with us
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
