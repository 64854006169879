import { motion } from "framer-motion";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import Hometext from "./components/Hometext";
import SMedia from "./components/SocialMedia";
import BSMedia from "./components/BSMedia";
import Footer from "./components/Footer";
import Ourteam from "./components/Ourteam";
import { Analytics } from "@vercel/analytics/react";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <div className="app">
      <Helmet>
        <title>DSpace - Digital Marketing Agency</title>
        <meta
          name="description"
          content="The DSpace digital marketing agency is here to grow your business Faster. Harness the power of digital marketing to grow your business"
        />
        <meta
          name="keywords"
          content="Digital marketing agency, Digital marketing agency in India, Digital marketing agency in Udaipur Digital marketing agency in Dubai, Digital marketing agency in the USA, Digital marketing agency in
          Europe, Best digital marketing agency, Best digital marketing agency in India, Best digital marketing agency in Udaipur, Digital marketing services, Digital marketing company, SEO, SMM, Content creation, Video creation, Graphic design, Website creation"
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <SMedia />
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="w-[90%] mx-auto">
                <motion.div margin="0 0 -200px 0" amount="all">
                  <Landing />
                </motion.div>
              </div>
              <div className="">
                <motion.div margin="0 0 -200px 0" amount="all">
                  <Hometext />
                </motion.div>
              </div>
            </div>
          }
        />
        <Route
          path="/aboutus"
          element={
            <div className="w-[90%] mx-auto">
              <motion.div margin="0 0 -200px 0" amount="all">
                <AboutUs />
              </motion.div>
            </div>
          }
        />
        <Route
          path="/services"
          element={
            <div className="w-[90%] mx-auto">
              <motion.div margin="0 0 -200px 0" amount="all">
                <Services />
              </motion.div>
            </div>
          }
        />
        <Route
          path="/ourteam"
          element={
            <div className="w-[90%] mx-auto">
              <motion.div margin="0 0 -200px 0" amount="all">
                <Ourteam />
              </motion.div>
            </div>
          }
        />
        <Route
          path="/contactus"
          element={
            <div className="w-[90%] mx-auto">
              <motion.div margin="0 0 -200px 0" amount="all">
                <ContactUs />
              </motion.div>
            </div>
          }
        />
      </Routes>
      <Footer />
      <BSMedia />
      <Analytics />
    </div>
  );
}

export default App;
