import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { MdContactMail } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import Phone from "../assets/phone.jpg";
import Contactt from "../assets/contactt.jpg";
import Contacttt from "../assets/contacttt.jpg";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <section id="contactus" className="contact mt-24 pb-10 text-[#1d2026]">
      <Helmet>
        <title>DSpace - ContactUs</title>
        <link rel="canonical" href="/contactus" />
      </Helmet>
      <p className="font-bold text-[35px] text-center md:text-[50px] mb-8 space-x-5">
        Contact
        <span className="text-red-600 ml-5"> दी</span>
        SPACE
      </p>
      <div className="upper bg-white p-2 rounded-md">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="flex flex-col sm:flex-row gap-5 items-center justify-evenly"
        >
          <div className="flex items-center  gap-2 text-xl font-semibold">
            <FiPhoneCall className=" text-3xl sm:text-4xl" />
            <p>Call us</p>
          </div>
          <div>
            <img loading="lazy" src={Phone} className="h-56" alt="" srcset="" />
          </div>
          <div>
            <a
              className="font-semibold text-xl sm:text-2xl mt-2 text-[#1053f3]"
              href="tel:+91 7665588851"
            >
              +91 7665588851
            </a>
          </div>
        </motion.div>
      </div>

      <div className="upper bg-white mt-5 p-2 rounded-md">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="flex flex-col sm:flex-row items-center gap-5 justify-between"
        >
          <div>
            <img
              loading="lazy"
              src={Contactt}
              className="h-64"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex items-center gap-2 text-xl font-semibold">
            <div className="flex flex-col items-center sm:flex-none">
              <div className="flex items-center gap-2 text-xl font-semibold">
                <MdContactMail className="text-2xl" />
                <p>Chat to us</p>
              </div>
              <p className="font-medium mb-1 text-base sm:text-lg">
                Our friendly team is here to help.
              </p>
              <a
                href="mailto:dspacemail@gmail.com"
                className="font-semibold text-xl sm:text-2xl text-[#1053f3]"
              >
                dspaceinfusion@gmail.com
              </a>
            </div>
          </div>
          <div>
            <img
              loading="lazy"
              src={Contacttt}
              className=" h-[300px] object-contain w-full"
              alt=""
              srcset=""
            />
          </div>
        </motion.div>
      </div>

      <div className="lowerr bg-white w-fit mx-auto mt-5 p-5 py-10 rounded-md">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="text-center"
        >
          <h1 className="text-xl sm:text-3xl font-bold ">Working Hours</h1>

          <div className="flex flex-col gap-3 sm:gap-5 justify-between mt-5 font-semibold text-lg sm:text-xl">
            <p>Mon-Fri - 9:30am to 6:30pm</p>
            <p>Saturday - 9:30am to 4:30pm</p>
            <p>Sunday - Just Chill</p>
          </div>
        </motion.div>
      </div>

      <div className=" bg-[#f8d200] mt-10 p-5  sm:w-[70%] mx-auto rounded-md">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className=""
        >
          <div>
            <p className="-playfair font-bold text-[26px] sm:text-[35px] mb-5 text-center  text-black">
              CONTACT US
            </p>
            <p className=" text-[#1d2026] ml-1 font-semibold text-center">
              Let's Team Up
            </p>
            <div className="flex md:justify-end my-5"></div>
          </div>
          <form
            target="_blank"
            onSubmit={onSubmit}
            action="https://formsubmit.co/0db3b92655034a222ec9d9a5ebaababb"
            method="POST"
          >
            <input
              className="w-full font-semibold placeholder-black text-[#1d2026] border-2 border-black outline-none bg-[#f8d200] p-3"
              type="text"
              placeholder="Name"
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.name && (
              <p className="text-red-500 mt-1">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "maxLength" && "Max length is 100 char."}
              </p>
            )}

            <input
              className="w-full bg-blue font-semibold placeholder-black border-2 border-black outline-none text-[#1d2026] bg-[#f8d200]  p-3 mt-5"
              type="text"
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="text-red-500 mt-1">
                {errors.email.type === "required" && "This field is required."}
                {errors.email.type === "pattern" && "Invalid email address."}
              </p>
            )}

            <input
              className="w-full bg-blue font-semibold placeholder-black border-2 border-black outline-none text-[#1d2026] bg-[#f8d200]  p-3 mt-5"
              type="text"
              placeholder="Phone"
              {...register("phone", {
                required: true,
                pattern: /^[0-9]{10}$/i,
              })}
            />
            {errors.phone && (
              <p className="text-red-500 mt-1">
                {errors.phone.type === "required" && "This field is required."}
                {errors.phone.type === "pattern" && "Invalid Phone number."}
              </p>
            )}

            <textarea
              className="w-full bg-blue font-semibold placeholder-black border-2 border-black outline-none text-[#1d2026] bg-[#f8d200]  p-3 mt-5"
              name="message"
              placeholder="Leave your message"
              rows="4"
              cols="50"
              {...register("message", {
                required: true,
                maxLength: 2000,
              })}
            />
            {errors.message && (
              <p className="text-red-600 mt-1">
                {errors.message.type === "required" &&
                  "This field is required."}
                {errors.message.type === "maxLength" &&
                  "Max length is 2000 char."}
              </p>
            )}

            <button
              className="p-5 w-full bg-myBlack text-lg font-semibold text-white mt-5"
              type="submit"
            >
              SEND
            </button>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
